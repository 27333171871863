var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增","width":1400,"confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-row',[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    { required: true, message: '请输入名称' },
                    { max: 64, message: '超出最大长度(64)' },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    { required: true, message: '请输入名称' },\n                    { max: 64, message: '超出最大长度(64)' },\n                  ],\n                },\n              ]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"生产线"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'production_line',
                {
                  rules: [{ required: true, message: '请选择生产线' }],
                },
              ]),expression:"[\n                'production_line',\n                {\n                  rules: [{ required: true, message: '请选择生产线' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},on:{"change":_vm.onChangeProductionLine}},[_c('a-select-option',{attrs:{"value":"a"}},[_vm._v("名创 A")]),_c('a-select-option',{attrs:{"value":"b"}},[_vm._v("名创 B")]),_c('a-select-option',{attrs:{"value":"c"}},[_vm._v("名创 C")])],1)],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"默认道口"}},[_c('CrossingSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:(['default_crossing', { rules: [{ required: true, message: '请选择默认道口' }] }]),expression:"['default_crossing', { rules: [{ required: true, message: '请选择默认道口' }] }]"}],attrs:{"productionLine":_vm.productionLine}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"分拣条件","label-col":{ span: 2 },"wrapper-col":{ span: 22 }}},[_c('SortingFilterTable',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sorting_filter_items']),expression:"['sorting_filter_items']"}],attrs:{"productionLine":_vm.productionLine}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }